import { React } from "react";
import { NavLink } from "react-router-dom";

export default function AdminSidebar() {
  return (
    <aside className="w-32 hidden lg:flex flex-col border-r-2 border-background-2 font-font-2 font-bold tracking-wide mx-10">
      <ul className="mt-16 grid gap-10 text-text-2">
        <li>
          <NavLink exact to="/admin/profile" activeClassName="text-accent-1">
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/admin/schedule" activeClassName="text-accent-1">
            Schedule
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/procedure" activeClassName="text-accent-1">
            Procedure
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/medication" activeClassName="text-accent-1">
            Medication
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/products" activeClassName="text-accent-1">
            Products
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/payment" activeClassName="text-accent-1">
            Payment
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/documents" activeClassName="text-accent-1">
            Documents
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/support" activeClassName="text-accent-1">
            Support
          </NavLink>
        </li>
      </ul>
    </aside>
  );
}
