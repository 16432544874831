import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";

export default function PatientReferrals() {
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [salons, setSalons] = useState([]);
  const [affiliates, setAffiliates] = useState("all");
  const [requests, setRequests] = useState(null);

  useEffect(() => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/patient/referrals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        salon_id: affiliates,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatientReferrals(response.patients);
          setSalons(response.salons);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, [affiliates]);

  const consumerReferrals = () => {
    fetch(`${process.env.REACT_APP_API}/patient/appointment-requests`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // setPatientReferrals(response.patients);
          // setSalons(response.salons);
          setRequests(response.data);
          console.log(response);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  };

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex pb-6 border-b-2 border-background-2">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
        </nav>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        {patientReferrals &&
        salons.length > 0 &&
        patientReferrals.length > 0 ? (
          <div className="flex flex-row">
            <aside
              className="w-52 hidden lg:flex flex-col border-r-2 border-background-2 font-font-2 font-bold  mx-10 "
              style={{ position: "sticky", top: 0 }}
            >
              <div className="mt-16">
                <p className="text-xl">Affiliate</p>
              </div>
              <ul className="mt-16 flex flex-col gap-5 text-text-2">
                {salons.length > 0 &&
                  salons.map((salon, index) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setAffiliates(salon.salon_id);
                      }}
                      key={index}
                    >
                      {salon.stylist_name} <br />({salon.salon_name})
                    </li>
                  ))}
                <li
                  className="cursor-pointer w-fit"
                  onClick={consumerReferrals}
                >
                  Consumer
                </li>
              </ul>
            </aside>
            <div className="mx-auto mt-20 grid gap-4">
              <div className="flex items-end justify-between">
                <p className="w-52 text-center text-xl tracking-widest">
                  Affiliate
                </p>
                <p className="w-96 text-xl tracking-widest">
                  New Patient Referrals
                </p>
                <div />
              </div>
              <section className="mt-10 items-end grid gap-10">
                {patientReferrals.map((patient) => {
                  return (
                    <div
                      key={patient.id}
                      className="flex items-end justify-evenly"
                    >
                      <p className="w-52 text-center">
                        {patient.referral.referred_by} <br />(
                        {patient.referral.salon_name})
                      </p>
                      <p className="ml-4 mr-8 p-2 w-96 bg-background-2 text-text-7 rounded-sm flex items-center justify-evenly">
                        <span className="truncate">
                          {patient.firstName} {patient.lastName}
                        </span>
                        <span className="font-normal text-sm">
                          DOB: {patient.dob}
                        </span>
                      </p>
                      <NavLink
                        to={{
                          pathname: `/patient/presentation/overview/${patient.id}`,
                          state: { status: patient.referral.status },
                        }}
                        className={`font-thin tracking-widest h-10 w-32 flex items-center justify-center ${
                          patient.referral.status === "accepted" &&
                          "bg-accent-1"
                        } ${
                          patient.referral.status === "pending" && "bg-button-4"
                        } rounded-lg text-sm text-text-3 focus:outline-none`}
                      >
                        {patient.referral.status === "pending"
                          ? "Review"
                          : "Accepted"}
                      </NavLink>
                    </div>
                  );
                })}
              </section>
              <section className="mt-10 items-end grid gap-10">
                {requests?.map((patient) => {
                  return (
                    <div
                      key={patient.id}
                      className="flex items-end justify-evenly"
                    >
                      <p className="w-52 text-center">
                        {patient.patient.referral} <br />
                        {/* ({patient.referral.salon_name}) */}
                      </p>
                      <p className="ml-4 mr-8 p-2 w-96 bg-background-2 text-text-7 rounded-sm flex items-center justify-evenly">
                        <span className="truncate">
                          {patient.patient.firstName} {patient.patient.lastName}
                        </span>
                        <span className="font-normal text-sm">
                          DOB: {patient.patient.dob}
                        </span>
                      </p>
                      <NavLink
                        to={{
                          pathname: `/patient/presentation/overview/${patient.id}`,
                          state: { status: patient.status },
                        }}
                        className={`font-thin tracking-widest h-10 w-32 flex items-center justify-center ${
                          patient.status === "accepted" && "bg-accent-1"
                        } ${
                          patient.status === "pending" && "bg-button-4"
                        } rounded-lg text-sm text-text-3 focus:outline-none`}
                      >
                        {patient.status === "pending" ? "Review" : "Accepted"}
                      </NavLink>
                    </div>
                  );
                })}
              </section>
            </div>
          </div>
        ) : (
          <div className="mx-auto my-auto text-2xl">No Referrals Found</div>
        )}
      </main>
      <Loading message="Loading Patient Referrals" ready={ready} />
    </>
  );
}
