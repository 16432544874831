import React, { useEffect, useRef, useState } from 'react'
import styles from './Agreement.module.css'
import Logo from '../../assets/images/logo.png'
import SignatureCanvas from 'react-signature-canvas'
import Loading from '../../views/Loading'
import { useHistory } from 'react-router-dom'

function HipaaAgreement() {
    const history = useHistory();
    const [successMessage, setSuccessMessage] = useState("")
    const [ready, setReady] = useState(false);
    const [message, setMessage] = useState("");
    const signature = useRef(null);
    const [agreeTandC, setAgreeTandC] = useState(false);

    useEffect(() => {
        setReady(true);
    }, []);

    const submitForm = (e) => {
        e.preventDefault()
        if(agreeTandC){
            setReady(false);
            fetch(`${process.env.REACT_APP_API}/clinic/save_hipaa_agreement`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    sign: signature.current.getTrimmedCanvas().toDataURL('image/png').substring(22)
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response);
                if (response.success) {
                    setSuccessMessage("Hipaa agreement Updated Successfully");
                    setTimeout(() => {
                        history.push("/dashboard");
                    }, 1000);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
                })
                .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
        }
    }
  return (
    <>
        {ready && (
            <div>
                <div className={styles.topContainer}>
                    <img src={Logo} alt="logo" className={styles.logo} />
                    <div className={styles.title}>PACIFIC HAIR CENTER</div>
                </div>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {successMessage && <p className="mt-8 text-center text-green-600">{successMessage}</p>}
                <form onSubmit={submitForm} className={styles.form}>
                    <div>HIPAA BUSINESS ASSOCIATE CONTRACT</div>
                    <div className={styles.agreement}></div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <input type="checkbox" style={{marginRight:'20px'}} value={agreeTandC} onChange={(e) => (setAgreeTandC(prev => !prev))} />
                        <div>BY SIGNING BELOW I AGREE TO ALL THE TERMS AND CONDITIONS</div>
                    </div>
                    <div className="bg-background-3 my-4">
                        <SignatureCanvas  ref={signature} penColor='green' canvasProps={{ style: { width: "100%", height: 150 }, className: 'sigCanvas' }} />
                    </div>
                    <button type="submit" className={styles.formButton}>CONFIRM</button>
                </form>
            </div>
            )}
            <Loading message={"Loading"} ready={ready} />
        </>
  )
}

export default HipaaAgreement