import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Subscription from '../pages/subscription/Subscription';
import Loading from '../views/Loading';

export default function Signup(props) {
    const {email_url, type, status} = useParams();
    const [clinicName, setClinicName] = useState('');
    const [email, setEmail] = useState(email_url ? email_url : "");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [ready, setReady] = useState(true);
    const [error, setError] = useState([]);
    const [activeScreen, setActiveScreen] = useState('signup');
    const history = useHistory();


    const signUp = () => {
        // if (isSignUpValid()) {
          setMessage("");
          setError([]);
          setReady(false);
          fetch(`${process.env.REACT_APP_API}/clinic/onboarding`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: clinicName.trim(),
              email: email.trim().toLowerCase(),
              // password: password,
            }),
          })
            .then(response => response.json())
            .then(response => {
              if (response.success) {
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('temp_user_id', response.data.id);
                initCheckout('setup');
              } else {
                setMessage(response.message);
                setError(response.error);
                setReady(true);
              }
            })
            .catch(error => {
              console.error(error);
              setMessage('Some Error Occured. Please Try Again Later');
              setReady(true);
            });
        // }
    };

    const initCheckout = async (type_temp) => {
      // if (isSignUpValid()) {
        setMessage("");
        setError([]);
        setReady(false);
        await fetch(`${process.env.REACT_APP_API}/clinic/onboarding/init_checkout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: type_temp,
            email: email.trim().toLowerCase(),
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              window.location.href = response.data.url;
            } else {
              if(response.payment_status){
                if(type_temp === 'setup'){ 
                  setActiveScreen('subscription');
                  initCheckout("annually");
                }else if(type_temp === "monthly" || type_temp === "annually"){
                  setActiveScreen("password_setup");
                  setReady(true);
                }
              }else{
                setActiveScreen('stripeCheckout');
                setMessage(response.message);
                setError(response.error);
                setReady(true);
              }
            }
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
      // }
    };

    const checkPayment = () => {
      setMessage("");
        setError([]);
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/onboarding/payment_status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: type,
            email: email.trim().toLowerCase(),
            // password: password,
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              if(response.payment_status){
                if(type === "setup"){
                  setActiveScreen('subscription');
                }else{
                  setActiveScreen('password_setup');
                }
              }else{
                setActiveScreen('stripeCheckout');
                setMessage("Your payment is not successful. Please try again later.");
              }
            } else {
              setActiveScreen('stripeCheckout');
              setMessage(response.message);
              setError(response.error);
            }
            window.history.pushState({}, null, "/onboarding");
            setReady(true);
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
    }

    const isSignUpValid = () => {
      if(password === confirmPassword){
        return true;
      }
      setError({confirmPassword: "Password does not match confirm password"});
      return false;
    };

    const setupPassword = () => {
      if (isSignUpValid()) {
        setMessage("");
        setError([]);
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/onboarding/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: password,
            email: email.trim().toLowerCase(),
            // password: password,
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              localStorage.setItem('access_token', response.access_token);
              localStorage.setItem('expires_at', (response.expires_at * 1000));
              props.setLoggedIn(true);
              history.push("/onboarding/md_details");
            } else {
              setMessage(response.message);
              setError(response.error);
              setReady(true);
            }
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
      }
    };

    useEffect(() => {
      if(email_url && type && status) {
        checkPayment();
      } else {
        setMessage('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
          <main className={`flex flex-col p-4 ${ready ? '' : 'hidden'}`}>
            {
              activeScreen === "signup" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <form
                      className="mt-4 flex flex-col items-center"
                      onSubmit={event => {
                        event.preventDefault();
                        signUp();
                      }}
                  >
                      <input
                        type="text"
                        className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="clinic name"
                        value={clinicName}
                        required
                        onChange={event => {
                          setClinicName(event.target.value);
                        }}
                      />
                      {error.name && <p className="text-center text-red-600">{error.name}</p>}
                      <input
                        type="email"
                        className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="email"
                        value={email}
                        required
                        onChange={event => {
                          setEmail(event.target.value);
                        }}
                      />
                      {error.email && <p className="text-center text-red-600">{error.email}</p>}
                      
                      <input
                        type="submit"
                        className="mt-8 h-20 w-20 rounded-full bg-accent-1 text-text-3 cursor-pointer focus:outline-none"
                        value="Next"
                      />
                  </form>
                  
              </div>
            }

            {
              activeScreen === "stripeCheckout" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <p className="mt-4 text-center">
                      <button
                        className="text-accent-1 hover:underline focus:outline-none focus:underline"
                        onClick={() => {
                          setActiveScreen("signup");
                          setError("");
                          setMessage(""); 
                        }}
                      >
                        Back
                      </button>
                  </p>
              </div>
            }

            {
              activeScreen === "subscription" && 
                  <Subscription initCheckout={initCheckout} />
            }

            {
              activeScreen === "password_setup" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <form
                      className="mt-4 flex flex-col items-center"
                      onSubmit={event => {
                        event.preventDefault();
                        setupPassword();
                      }}
                  > 
                      <input
                        type="password"
                        className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="password"
                        value={password}
                        minLength="8"
                        required
                        onChange={event => {
                          setPassword(event.target.value);
                        }}
                      />
                       {error.password && <p className="text-center text-red-600">{error.password}</p>}
                      <input
                        type="password"
                        className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="confirm password"
                        value={confirmPassword}
                        minLength="8"
                        required
                        onChange={event => {
                          setConfirmPassword(event.target.value);
                        }}
                      />
                      {error.confirmPassword && <p className="text-center text-red-600">{error.confirmPassword}</p>}
                      
                      <input
                        type="submit"
                        className="mt-8 h-20 w-20 rounded-full bg-accent-1 text-text-3 cursor-pointer focus:outline-none"
                        value="Next"
                      />
                  </form>
                  
              </div>
            }
          </main>
          <Loading
            message='Registering Clinic'
            ready={ready}
          />
          <footer className="px-4 pt-4 pb-8 border-t-2 border-accent-2 text-center">
            GRO technologies
          </footer>
        </>
    );
}