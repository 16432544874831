import React, { useState, useEffect, useRef } from "react";
import PatientNavbar from "../components/PatientNavbar";
import { useParams, NavLink } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { FaDotCircle } from "react-icons/fa";
import GloPresentationNavbar from "../components/GloPresentationNavbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GLOFront from "../assets/images/GLOFront.png";
import { IoMdClose } from "react-icons/io";
import dayjs from "dayjs";
import Loading from "./Loading";

function GloPresentation() {
  const { id } = useParams();
  const [ready, setReady] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patientReferrals, setPatientReferrals] = useState(null);

  const [active, setActive] = useState("global");
  const [openImage, setOpenImage] = useState(false);
  const [openCloseUp, setOpenCloseUp] = useState(false);

  const [currentId, setCurrentId] = useState("-1");
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [viewMode, setViewMode] = useState("global");
  const [indexMode, setIndexMode] = useState("density");
  const stackedData = [
    0,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    100,
  ];

  const [faceAnalysis, setFaceAnalysis] = useState([]);
  const [spotsColor, setSpotsColor] = useState("brown_spot");
  const imageTypes = {
    global: ["front", "left", "right"],
    closeup: {
      front: ["forehead", "upper_left_cheek", "upper_right_cheek","lower_left_cheek","lower_right_cheek"],
      left: ["upper_cheek", "lower_cheek"],
      right: ["upper_cheek", "lower_cheek"],
    },
  };
  const [globalIndex, setGlobalIndex] = useState(0);
  const [closeupIndex, setCloseupIndex] = useState(0);

  const chartColor = [
    "#86634C",
    "#86634C",
    "#AC7E6A",
    "#AC7E6A",
    "#BA8D77",
    "#BA8D77",
    "#D5AF9A",
    "#D5AF9A",
    "#F0D1BA",
    "#F0D1BA",
    "#EFD5C5FA",
    "#F4DED1",
  ];

  console.log(faceAnalysis);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/face_analysis/all?patient_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setCurrentId(0);
          setFaceAnalysis(response.face_analysis);
        } else {
          //setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        //setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          //setMessage(response.message);
        }
      })
      .finally(() => {
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        //setMessage("Some Error Occured. Please Try Again Later");
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={id} tab={6} gloTrack={true} />
        <section className="mt-6">
          <div>
            {patientReferrals ? (
              <h1
                className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4 mt-4"
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1
                className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4 mt-4"
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                {`${firstName} ${lastName}`}
              </h1>
            )}

            <div className="flex justify-center items-center mb-2">
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId > 0) {
                    setCurrentId(currentId - 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#6e7884"
                  strokeWidth="1.25"
                >
                  <path
                    fill="#6e7884"
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                  />
                </svg>
              </button>
              <p
                className="mx-4 text-text-8"
                style={{ color: "rgba(255, 255, 255, 0.4)" }}
              >
                {faceAnalysis[currentId]
                  ? dayjs
                      .unix(faceAnalysis[currentId].created_at)
                      .format("MM-DD-YY")
                  : ""}
              </p>
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId + 1 < faceAnalysis.length) {
                    setCurrentId(currentId + 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#6e7884"
                  strokeWidth="1.25"
                >
                  <path
                    fill=" #6e7884"
                    d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>

        <div className="mx-auto container flex flex-col p-4">
          <div
            className="bg-background-5 py-10"
            style={{ height: "var(--height-1)" }}
          >
            <div className="text-center text-lg relative">
              <span
                className="capitalize text-text-4 text-lg"
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                {active !== 'global' ? spotsColor.replaceAll("_", " ") : 'Pigmentation'}
              </span>
              {openCloseUp && (
                <div
                  className="absolute right-10 top-0 w-fit cursor-pointer"
                  onClick={() => {
                    if (openImage) {
                      setOpenImage(false);
                    }
                    if (openCloseUp) {
                      setOpenCloseUp(false);
                    }
                  }}
                >
                  <IoMdClose style={{ fontSize: "30px" }} />
                </div>
              )}
              {openImage && (
                <div
                  className="absolute right-10 top-0 w-fit cursor-pointer"
                  onClick={() => {
                    if (openImage) {
                      setOpenImage(false);
                    }
                    if (openCloseUp) {
                      setOpenCloseUp(false);
                    }
                  }}
                >
                  <IoMdClose style={{ fontSize: "30px" }} />
                </div>
              )}
            </div>
            {/* <div>
              {openImage ? (
                <div
                  className="mt-2 mb-1 text-center capitalize"
                  style={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Global {imageTypes.global[globalIndex]}
                </div>
              ) : openCloseUp ? (
                <div
                  className="mt-2 mb-1 text-center capitalize"
                  style={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  {imageTypes.closeup[imageTypes.global[globalIndex]][
                    closeupIndex
                  ].replaceAll("_", " ")}
                </div>
              ) : (
                <div className="flex items-center justify-center gap-16 mt-2 mb-12 text-base">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => setActive("global")}
                    style={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    <GoPrimitiveDot
                      className={
                        active === "global"
                          ? "text-accent-5 text-lg"
                          : "text-lg text-white"
                      }
                    />
                    Global
                  </div>
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => setActive("close-up")}
                    style={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    <GoPrimitiveDot
                      className={
                        active === "close-up"
                          ? "text-accent-5 text-lg"
                          : "text-lg text-white"
                      }
                    />
                    Close-up
                  </div>
                </div>
              )}
            </div> */}
            <div className="flex justify-centern items-center h-7/10">
              {openImage ? (
                <>
                  <div className="w-full">
                    <div className="flex flex-wrap items-center justify-center mt-20 ">
                      <div className="w-1/12 text-center">
                        <button
                          className="text-2xl focus:outline-none"
                          onClick={function () {
                            if (currentId > 0) {
                              setCurrentId(currentId - 1);
                            }
                          }}
                        >
                          <svg
                            // className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            width="30"
                            height="30"
                          >
                            <path
                              fill="#6e7884"
                              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="w-5/6">
                        <div className="grid grid-cols-3 flex items-center justify-center gap-10 py-6 mt-0 m-auto">
                          {faceAnalysis.map((analysis, idx) => {
                            if (
                              idx == currentId ||
                              idx == currentId - 1 ||
                              idx == currentId + 1
                            ) {
                              return (
                                analysis &&
                                analysis[spotsColor] && (
                                  <div
                                    className={`flex justify-center items-center ${
                                      currentId === 0 && idx === 0
                                        ? "col-start-2"
                                        : ""
                                    }`}
                                  >
                                    {analysis[spotsColor].global[
                                      imageTypes.global[globalIndex]
                                    ]?.image_path ? (
                                      <img
                                        src={
                                          analysis[spotsColor].global[
                                            imageTypes.global[globalIndex]
                                          ].image_path
                                        }
                                        style={{ width: "230px" }}
                                      />
                                    ) : (
                                      <div className="flex justify-center items-center">
                                        <div className="w-56 h-72 flex justify-center items-center">
                                          No image found
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              );
                            } else {
                              return "";
                            }
                          })}
                        </div>
                        <div className="grid grid-cols-3 flex items-center justify-center gap-20 py-6 mt-6 m-auto border-t border-lightgrey-200 ">
                          {faceAnalysis.map((analysis, idx) => {
                            if (
                              idx == currentId ||
                              idx == currentId - 1 ||
                              idx == currentId + 1
                            ) {
                              return (
                                analysis &&
                                analysis[spotsColor] && (
                                  <div
                                    className={`flex justify-center items-center ${
                                      currentId === 0 && idx === 0
                                        ? "col-start-2"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex items-center justify-center gap-2 text-lg">
                                      <div>
                                        {dayjs
                                          .unix(analysis.created_at)
                                          .format("MM-DD-YY")}
                                      </div>
                                      {analysis[spotsColor].global[
                                        imageTypes.global[globalIndex]
                                      ]?.glo_index === 0 ? (
                                        <div className="bg-accent-5 text-white w-12 h-12 border-2 border-white rounded-full flex items-center justify-center">
                                          {parseInt(
                                            analysis[spotsColor].global[
                                              imageTypes.global[globalIndex]
                                            ]?.glo_index
                                          )}
                                          %
                                        </div>
                                      ) : (
                                        <div className="bg-accent-5 text-white w-12 h-12 border-2 border-white rounded-full flex items-center justify-center">
                                          {parseInt(
                                            analysis[spotsColor].global[
                                              imageTypes.global[globalIndex]
                                            ]?.glo_index
                                          )}
                                          %
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div className="w-1/12 text-center">
                        <button
                          className="text-2xl focus:outline-none"
                          onClick={function () {
                            if (currentId + 1 < faceAnalysis.length) {
                              setCurrentId(currentId + 1);
                            }
                          }}
                        >
                          <svg
                            // className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            width="30"
                            height="30"
                          >
                            <path
                              fill="#6e7884"
                              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : openCloseUp ? (
                <>
                  <div className="w-full">
                    <div className="flex flex-wrap items-center justify-center mt-24 ">
                      <div className="w-1/12 text-center">
                        <button
                          className="text-2xl focus:outline-none"
                          onClick={function () {
                            if (currentId > 0) {
                              setCurrentId(currentId - 1);
                            }
                          }}
                        >
                          <svg
                            // className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            width="30"
                            height="30"
                          >
                            <path
                              fill="#6e7884"
                              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="w-5/6">
                        <div className="grid grid-cols-3 flex items-center justify-center gap-10 py-6 mt-0 m-auto">
                          {faceAnalysis.map((analysis, idx) => {
                            if (
                              idx == currentId ||
                              idx == currentId - 1 ||
                              idx == currentId + 1
                            ) {
                              return (
                                analysis &&
                                analysis[spotsColor] && (
                                  <div
                                    className={`flex justify-center items-center ${
                                      currentId === 0 && idx === 0
                                        ? "col-start-2"
                                        : ""
                                    }`}
                                  >
                                    {analysis[spotsColor].closeup[
                                      imageTypes.global[globalIndex]
                                    ] &&
                                    analysis[spotsColor].closeup[
                                      imageTypes.global[globalIndex]
                                    ][
                                      imageTypes.closeup[
                                        imageTypes.global[globalIndex]
                                      ][closeupIndex]
                                    ]?.image_path ? (
                                      <img
                                        src={
                                          analysis[spotsColor].closeup[
                                            imageTypes.global[globalIndex]
                                          ][
                                            imageTypes.closeup[
                                              imageTypes.global[globalIndex]
                                            ][closeupIndex]
                                          ]?.image_path
                                        }
                                        style={{ width: "230px" }}
                                      />
                                    ) : (
                                      <div className="flex justify-center items-center">
                                        <div className="w-56 h-72 flex justify-center items-center">
                                          No image found
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              );
                            } else {
                              return "";
                            }
                          })}
                        </div>
                        <div className="grid grid-cols-3 flex items-center justify-center gap-20 py-6 mt-6 m-auto border-t border-lightgrey-200 ">
                          {faceAnalysis.map((analysis, idx) => {
                            if (
                              idx == currentId ||
                              idx == currentId - 1 ||
                              idx == currentId + 1
                            ) {
                              return (
                                analysis &&
                                analysis[spotsColor] && (
                                  <div
                                    className={`flex justify-center items-center ${
                                      currentId === 0 && idx === 0
                                        ? "col-start-2"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex items-center justify-center gap-2 text-lg">
                                      <div>
                                        {dayjs
                                          .unix(analysis.created_at)
                                          .format("MM-DD-YY")}
                                      </div>
                                      {analysis[spotsColor].closeup[
                                        imageTypes.global[globalIndex]
                                      ] &&
                                      analysis[spotsColor].closeup[
                                        imageTypes.global[globalIndex]
                                      ][
                                        imageTypes.closeup[
                                          imageTypes.global[globalIndex]
                                        ][closeupIndex]
                                      ]?.glo_index === 0 ? (
                                        <div className="bg-accent-5 text-white w-12 h-12 border-2 border-white rounded-full flex items-center justify-center">
                                          {parseInt(
                                            analysis[spotsColor].closeup[
                                              imageTypes.global[globalIndex]
                                            ][
                                              imageTypes.closeup[
                                                imageTypes.global[globalIndex]
                                              ][closeupIndex]
                                            ]?.glo_index
                                          )}
                                          %
                                        </div>
                                      ) : (
                                        <div className="bg-accent-5 text-white w-12 h-12 border-2 border-white rounded-full flex items-center justify-center">
                                          {parseInt(
                                            analysis[spotsColor].closeup[
                                              imageTypes.global[globalIndex]
                                            ][
                                              imageTypes.closeup[
                                                imageTypes.global[globalIndex]
                                              ][closeupIndex]
                                            ]?.glo_index
                                          )}
                                          %
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div className="w-1/12 text-center">
                        <button
                          className="text-2xl focus:outline-none"
                          onClick={function () {
                            if (currentId + 1 < faceAnalysis.length) {
                              setCurrentId(currentId + 1);
                            }
                          }}
                        >
                          <svg
                            // className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            width="30"
                            height="30"
                          >
                            <path
                              fill="#6e7884"
                              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex items-center mt-16 py-5 w-full">
                  <div className="w-4/12 text-center">
                    <div className="text-center">
                      <div
                        className="capitalize text-text-4 text-md"
                        style={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        GLO Index <br />({spotsColor.replaceAll("_", " ")})
                      </div>
                      <div
                        className="mt-4 ml-28 flex flex-col items-center"
                        //style={{ marginLeft: 60 }}
                      >
                        {indexMode === "density" && (
                          <div className="flex flex-col mt-4 w-30">
                            {/* <p className="text-left text-sm mb-3">100</p> */}
                            {stackedData.reverse().map((data, index) => {
                              console.log(imageTypes);
                              const d =
                                active !== "close-up"
                                  ? faceAnalysis[currentId] &&
                                    parseInt(
                                      faceAnalysis[currentId][spotsColor]
                                        .global[imageTypes.global[globalIndex]]
                                        ?.glo_index || 0
                                    )
                                  : faceAnalysis.length !== 0
                                  ? faceAnalysis[currentId][spotsColor].closeup[
                                      imageTypes.global[globalIndex]
                                    ]
                                    ? parseInt(
                                        faceAnalysis[currentId][spotsColor]
                                          .closeup[
                                          imageTypes.global[globalIndex]
                                        ][
                                          imageTypes.closeup[
                                            imageTypes.global[globalIndex]
                                          ][closeupIndex]
                                        ]?.glo_index || 0
                                      )
                                    : 0
                                  : 0;
                              console.log(d);
                              let i = 6;
                              // if (d > 0 && d <= 14) i = 6;
                              // else if (d > 14 && d <= 28) i = 5;
                              // else if (d > 28 && d <= 42) i = 4;
                              // else if (d > 42 && d <= 56) i = 3;
                              // else if (d > 56 && d <= 70) i = 2;
                              // else if (d > 70 && d <= 84) i = 1;
                              // else if (d === 0) i = 6;
                              // else if (d === 100) i = 0;
                              // else i = 0;

                              if (d < -10) i = 11;
                              else if (d > -10 && d < 0) i = 10;
                              else if (d >= 0 && d <= 10) i = 9;
                              else if (d > 10 && d <= 20) i = 8;
                              else if (d > 20 && d <= 30) i = 7;
                              else if (d > 30 && d <= 40) i = 6;
                              else if (d > 40 && d <= 50) i = 5;
                              else if (d > 50 && d <= 60) i = 4;
                              else if (d > 60 && d <= 70) i = 3;
                              else if (d > 70 && d <= 80) i = 2;
                              else if (d > 80 && d <= 90) i = 1;
                              else if (d > 90) i = 0;
                              return (
                                <div
                                  className="flex flex-row relative hover:text-accent-3"
                                  key={index}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        chartColor[
                                          chartColor.length - 1 - index
                                        ],
                                      zIndex: "50 !important",
                                    }}
                                    className={`flex items-center justify-center w-4 h-6 text-text-6 font-normal transform border-2 border-transparent bg-background-3 mr-4 ml-1 ${
                                      i === index || index === 9 ? "z-10" : ""
                                    }`}
                                  >
                                    {index === i && (
                                      <div
                                        style={{ backgroundColor: "#E362B7" }}
                                        className={`absolute w-16 h-16 font-bold scale-125 ${parseFloat(d).toFixed(0) !== '0' ? "z-20" : 'z-10'} rounded-full shadow-strong border-2 border-white text-white flex items-center justify-center`}
                                      >
                                        {parseFloat(d).toFixed(0)}%
                                      </div>
                                    )}
                                    {index === 9 && (
                                      <div
                                        style={{ backgroundColor: "#E362B7" }}
                                        className="absolute w-16 h-10 font-bold scale-125 z-10 rounded-md shadow-strong border-2 border-white text-white flex items-center justify-center"
                                      >
                                        {`VI`}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="flex items-center justify-start w-28 h-6 px-3 text-xs leading-none text-left font-normal"
                                    style={
                                      (i === index && { color: "#0eb5f0" }) ||
                                      {}
                                    }
                                  >
                                    {index === 9 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "0.5vh",
                                        }}
                                      >
                                        Baseline
                                        <div>
                                          {faceAnalysis[0]
                                            ? dayjs
                                                .unix(
                                                  faceAnalysis[0]?.created_at
                                                )
                                                .format("MM-DD-YY")
                                            : ""}
                                        </div>
                                      </div>
                                    )}
                                    {index === i &&
                                    !(d >= 0 && d <= 14) &&
                                    faceAnalysis[currentId]
                                      ? dayjs
                                          .unix(
                                            faceAnalysis[currentId]?.created_at
                                          )
                                          .format("MM-DD-YY")
                                      : ""}
                                    {index === 0 && " Even skin color"}
                                  </div>
                                </div>
                              );
                            })}
                            {/* <p className="text-left text-sm mt-3 pl-1.5">0</p> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* close-up center image */}
                  {active === "close-up" && (
                    <div className="w-4/12 text-center">
                      {faceAnalysis[currentId] &&
                        faceAnalysis[currentId][spotsColor] && (
                          <>
                            <div
                              className="mb-3 text-center capitalize text-text-4 text-md"
                              style={{ color: "rgba(255, 255, 255, 0.7)" }}
                            >
                              {imageTypes.closeup[
                                imageTypes.global[globalIndex]
                              ][closeupIndex].replaceAll("_", " ")}
                            </div>
                            <div
                              className="w-full m-auto cursor-pointer flex items-center justify-center"
                              onDoubleClick={() => setOpenCloseUp(true)}
                            >
                              {faceAnalysis[currentId][spotsColor].closeup[
                                imageTypes.global[globalIndex]
                              ] &&
                              faceAnalysis[currentId][spotsColor].closeup[
                                imageTypes.global[globalIndex]
                              ][
                                imageTypes.closeup[
                                  imageTypes.global[globalIndex]
                                ][closeupIndex]
                              ]?.image_path ? (
                                <img
                                  src={
                                    faceAnalysis[currentId][spotsColor].closeup[
                                      imageTypes.global[globalIndex]
                                    ][
                                      imageTypes.closeup[
                                        imageTypes.global[globalIndex]
                                      ][closeupIndex]
                                    ].image_path
                                  }
                                  //className="w-full"
                                  style={{ width: "300px" }}
                                />
                              ) : (
                                <div className="h-48 flex justify-center items-center">
                                  <p>Image not found</p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  )}

                  {/* close-up right image */}
                  <div className={"text-center w-4/12"}>
                    {faceAnalysis[currentId] &&
                      faceAnalysis[currentId][spotsColor] && (
                        <>
                          <div className="mb-3 text-center capitalize text-text-4 text-md">
                            {imageTypes.global[globalIndex]}
                          </div>
                          <div className="flex items-center justify-center">
                            {active !== "close-up" && (
                              <button
                                className="font-bold text-2xl focus:outline-none"
                                onClick={function () {
                                  if (globalIndex > 0) {
                                    setCloseupIndex(0);
                                    setGlobalIndex((prev) => prev - 1);
                                  }
                                }}
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  stroke="#bfc5ca"
                                  strokeWidth="1.25"
                                >
                                  <path
                                    fill="#bfc5ca"
                                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                                  />
                                </svg>
                              </button>
                            )}
                            <div
                              onDoubleClick={() => setOpenImage(true)}
                              className={` ${active === "close-up" ? "" : "cursor-pointer"} mx-3`}
                            >
                              {faceAnalysis[currentId][spotsColor].global[
                                imageTypes.global[globalIndex]
                              ]?.image_path ? (
                                <div
                                  style={
                                    active === "close-up"
                                      ? {
                                          width: "280px",
                                          position: "relative",
                                        }
                                      : {
                                          width: "350px",
                                          // padding: "0 1rem",
                                          position: "relative",
                                        }
                                  }
                                >
                                  <img
                                    src={
                                      faceAnalysis[currentId][spotsColor]
                                        .global[imageTypes.global[globalIndex]]
                                        .image_path
                                    }
                                    style={{ width: "280px", margin: "0 auto" }}
                                  />
                                  {active === "close-up" && (
                                    <>
                                      {globalIndex === 0 && faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]] &&(
                                        <>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #E362B7",
                                              width: "18px",
                                              height: "18px",
                                              top: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['forehead']
                                              .y_axis+"%",
                                              left: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['forehead']
                                              .x_axis+"%",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                closeupIndex === 0
                                                  ? "#E362B7"
                                                  : "transparent",
                                              transform: 'translate(-50%, -50%)',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setCloseupIndex(0)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #E362B7",
                                              width: "18px",
                                              height: "18px",
                                              top: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['upper_left_cheek']
                                              .y_axis+"%",
                                              left: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['upper_left_cheek']
                                              .x_axis+"%",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                closeupIndex === 1
                                                  ? "#E362B7"
                                                  : "transparent",
                                                  transform: 'translate(-50%, -50%)',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setCloseupIndex(1)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #E362B7",
                                              width: "18px",
                                              height: "18px",
                                              top: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['upper_right_cheek']
                                              .y_axis+"%",
                                              left: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['upper_right_cheek']
                                              .x_axis+"%",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                closeupIndex === 2
                                                  ? "#E362B7"
                                                  : "transparent",
                                              transform: 'translate(-50%, -50%)',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setCloseupIndex(2)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #E362B7",
                                              width: "18px",
                                              height: "18px",
                                              top: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['lower_left_cheek']
                                              .y_axis+"%",
                                              left: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['lower_left_cheek']
                                              .x_axis+"%",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                closeupIndex === 3
                                                  ? "#E362B7"
                                                  : "transparent",
                                              transform: 'translate(-50%, -50%)',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setCloseupIndex(3)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #E362B7",
                                              width: "18px",
                                              height: "18px",
                                              top: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['lower_right_cheek']
                                              .y_axis+"%",
                                              left: faceAnalysis[currentId][spotsColor]
                                              .closeup[imageTypes.global[globalIndex]]['lower_right_cheek']
                                              .x_axis+"%",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                closeupIndex === 4
                                                  ? "#E362B7"
                                                  : "transparent",
                                              transform: 'translate(-50%, -50%)',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setCloseupIndex(4)}
                                          ></section>
                                        </>
                                      )}
                                      {globalIndex === 1 && (
                                        <>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #F6F7FA",
                                              width: "29px",
                                              height: "18px",
                                              right: "65px",
                                              top: "140px",
                                              backgroundColor:
                                                closeupIndex === 0
                                                  ? "#E362B7"
                                                  : "transparent",
                                            }}
                                            onClick={() => setCloseupIndex(0)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #F6F7FA",
                                              width: "29px",
                                              height: "18px",
                                              right: "75px",
                                              top: "170px",
                                              backgroundColor:
                                                closeupIndex === 1
                                                  ? "#E362B7"
                                                  : "transparent",
                                            }}
                                            onClick={() => setCloseupIndex(1)}
                                          ></section>
                                        </>
                                      )}
                                      {globalIndex === 2 && (
                                        <>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #F6F7FA",
                                              width: "29px",
                                              height: "18px",
                                              left: "65px",
                                              top: "140px",
                                              backgroundColor:
                                                closeupIndex === 0
                                                  ? "#E362B7"
                                                  : "transparent",
                                            }}
                                            onClick={() => setCloseupIndex(0)}
                                          ></section>
                                          <section
                                            className="absolute"
                                            style={{
                                              border: "2px solid #F6F7FA",
                                              width: "29px",
                                              height: "18px",
                                              left: "75px",
                                              top: "170px",
                                              backgroundColor:
                                                closeupIndex === 1
                                                  ? "#E362B7"
                                                  : "transparent",
                                            }}
                                            onClick={() => setCloseupIndex(1)}
                                          ></section>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div className="h-56 flex justify-center items-center w-48">
                                  Image not found
                                </div>
                              )}
                            </div>
                            {active !== "close-up" && (
                              <button
                                className="font-bold text-2xl focus:outline-none"
                                onClick={function () {
                                  if (
                                    globalIndex + 1 <
                                    imageTypes.global.length
                                  ) {
                                    setCloseupIndex(0);
                                    setGlobalIndex((prev) => prev + 1);
                                  }
                                }}
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  stroke="#bfc5ca"
                                  strokeWidth="1.25"
                                >
                                  <path
                                    fill=" #bfc5ca"
                                    d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <GloPresentationNavbar
            spotsColor={spotsColor}
            setSpotsColor={setSpotsColor}
            active={active}
            setActive={setActive}
          />
        </div>
      </main>
      <Loading message={"Loading Glo Tracker"} ready={ready} />
    </>
  );
}

export default GloPresentation;
