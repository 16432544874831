import { React } from "react";
import { NavLink } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { FaDotCircle } from "react-icons/fa";

export default function GloPresentationNavbar(props) {
  return (
    <nav
      className="mt-8 grid grid-cols-3 gap-12 font-normal font-font-2 text-center text-text-10 text-lg"
      style={{ height: "62px" }}
    >
      <button
        onClick={() => {
          props.setSpotsColor("brown_spot");
          props.setActive("close-up");
        }}
        className="relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <GoPrimitiveDot
          style={{ fontSize: "40px", color: "#E8BEAC", position: "absolute" }}
        />
        <p
          className={`mx-auto ${props.tab === 4 && "text-text-9 font-bold"} ${
            props.spotsColor === "brown_spot" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          Brown spots
        </p>
      </button>
      <button
        onClick={() => {
          props.setSpotsColor("red_spot");
          props.setActive("close-up");
        }}
        className="relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <GoPrimitiveDot
          style={{ fontSize: "40px" }}
          className="absolute text-accent-5"
        />
        <p
          className={`mx-auto ${props.tab === 1 && "text-text-9 font-bold"} ${
            props.spotsColor === "red_spot" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          Red spots
        </p>
      </button>
      {console.log(props.spotsColor)}
      <button
        onClick={() => {
          props.setSpotsColor("pores");
          props.setActive("close-up");
        }}
        className="relative flex items-center bg-background-5 py-2 px-5 border-b-2 border-background-6"
      >
        <FaDotCircle
          style={{ fontSize: "21px" }}
          className="absolute text-#AABCBF"
        />
        <p
          className={`mx-auto ${props.tab === 3 && "text-text-9 font-bold"} ${
            props.spotsColor === "pores" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          Pores
        </p>
      </button>
    </nav>
  );
}
