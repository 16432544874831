import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import PatientNavbar from '../components/PatientNavbar';
import PresentationNavbar from '../components/PresentationNavbar';
// import hairImagesFront from "../assets/images/hairImagesFront.png";
// import hairImagesVertex from "../assets/images/hairImagesVertex.png";
import dayjs from 'dayjs';
import Loading from './Loading';

const HairImages = () => {
  const { id } = useParams();

  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [viewMode, setViewMode] = useState('front');
  const [currentId, setCurrentId] = useState(0);
  const [hairImagesDetails, setHairImagesDetails] = useState([]);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [positionTab, setPositionTab] = useState(['front', 'crown', 'vertex', 'back', 'left', 'right']);
  // useEffect(() => {
  //   if(hairImagesDetails){
  //     setDate(hairImagesDetails[currentId].date);
  //   }
  // }, [currentId,hairImagesDetails]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setPatientReferrals(response.referral);
          var imageDetails = [];
          for (let index = 0; index < response.hair_analysis.length; index++) {
            let temp = {};
            temp['date'] = response.hair_analysis[index].created_at;
            response.hair_analysis[index].images.portrait.forEach((image) => {
              if (positionTab.indexOf(image.subType) === -1) {
                positionTab.push(image.subType)
              }
              temp[image.subType] = {
                imgSrc: image.image_path,
                hairDensityScore: response.hair_analysis[index].groindex.hairDensity[image.subType]
              };
            })
            // temp['front'] = [];
            // temp['crown'] = [];
            // temp['vertex'] = [];
            // temp['back'] = [];
            // temp['left'] = [];
            // temp['right'] = [];
            // temp['front'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'front'
            // );
            // temp['crown'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'crown'
            // );
            // temp['vertex'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'vertex'
            // );
            // temp['left'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'left'
            // );
            // temp['back'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'occipital'
            // );
            // temp['right'].imgSrc = getImagePath(
            //   response.hair_analysis[index].images.portrait,
            //   'right'
            // );

            // temp['front'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.front;
            // temp['crown'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.crown;
            // temp['vertex'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.vertex;
            // temp['left'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.left;
            // temp['back'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.back;
            // temp['right'].hairDensityScore =
            //   response.hair_analysis[index].groindex.hairDensity.right;
            imageDetails.push(temp);
          }
          setPositionTab(positionTab)
          setHairImagesDetails(imageDetails);
          setCurrentId(0);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      });
  }, [id, positionTab]);

  // const getImagePath = (array, subType) => {
  //   for (var index = 0; index < array.length; index++) {
  //     if (array[index].subType === subType) {
  //       return array[index].image_path;
  //     }
  //   }
  //   return null;
  // };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="mt-6">
          <div>
            {patientReferrals ? (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName}`}
              </h1>
            )}
            <div className="flex flex-col items-center px-12 md:justify-between md:flex-row">
              <button className="flex items-center my-2 mr-12 focus:outline-none">
                <NavLink
                  to={`/patient/presentation/hair-images/global-images/${id}`}
                >
                  <h2 className="text-xl font-bold text-text-3">
                    Global Images
                  </h2>
                </NavLink>
              </button>
              <div className="flex items-center my-2 ml-2">
                <button
                  className="text-2xl font-bold focus:outline-none"
                  onClick={function () {
                    if (currentId > 0) {
                      setCurrentId(currentId - 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill="#bfc5ca"
                      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    />
                  </svg>
                </button>
                <p className="mx-4 text-center">
                  {hairImagesDetails[currentId]
                    ? dayjs
                        .unix(hairImagesDetails[currentId].date)
                        .format('M-D-YYYY')
                    : ''}
                </p>
                <button
                  className="text-2xl font-bold focus:outline-none"
                  onClick={function () {
                    if (currentId + 1 < hairImagesDetails.length) {
                      setCurrentId(currentId + 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill=" #bfc5ca"
                      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    />
                  </svg>
                </button>
              </div>
              <button className="flex items-center my-2 focus:outline-none">
                <NavLink
                  to={`/patient/presentation/hair-images/close-up-hair-images/${id}`}
                >
                  <h2 className="text-xl font-bold text-text-1">
                    Close-up Hair Images
                  </h2>
                </NavLink>
              </button>
            </div>
          </div>
          <div
            className="px-4 py-8 mt-4 bg-background-5 overflow-hidden"
            style={{ height: 'var(--height-2)' }}
          >
            <div className="flex items-center justify-center mx-auto">
              {positionTab.map(tab => <button
                className={`${
                  viewMode === tab && 'text-text-3 '
                } capitalize text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode(tab)}
              >
                {tab}
              </button>)}
              {/* <button
                className={`${
                  viewMode === 'crown' && 'text-text-3 '
                } text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode('crown')}
              >
                Crown
              </button>
              <button
                className={`${
                  viewMode === 'vertex' && 'text-text-3 '
                } text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode('vertex')}
              >
                Vertex
              </button>
              <button
                className={`${
                  viewMode === 'back' && 'text-text-3 '
                } text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode('back')}
              >
                Back
              </button>
              <button
                className={`${
                  viewMode === 'left' && 'text-text-3 '
                } text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode('left')}
              >
                Left
              </button>
              <button
                className={`${
                  viewMode === 'right' && 'text-text-3 '
                } text-base font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => setViewMode('right')}
              >
                Right
              </button> */}
            </div>
            <div className="flex items-center justify-center">
              <button
                className="text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId > 0) {
                    setCurrentId(currentId - 1);
                  }
                }}
              >
                <svg
                  // className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 512"
                  width="50"
                  height="50"
                >
                  <path
                    fill="#6e7884"
                    d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                  />
                </svg>
              </button>
              <div className="grid justify-center flex-grow grid-cols-3 mt-2">
                {(
                  <>
                    {hairImagesDetails.map(
                      (hID, idx) =>
                        (idx === currentId - 1 ||
                          idx === currentId ||
                          idx === currentId + 1) && (
                          <div
                            key={idx}
                            className={
                              currentId === 0 && idx === 0 ? 'col-start-2' : ''
                            }
                          >
                            <div
                              className="flex flex-col items-center justify-start p-4"
                              style={{ width: '400px' }}
                            >
                              <div className="mt-4">
                                {hID[viewMode] && hID[viewMode].imgSrc ? (
                                  <img
                                    src={hID[viewMode].imgSrc}
                                    alt="Front Img"
                                    style={{ maxWidth: '100%' }}
                                    className="h-96"
                                  />
                                ) : (
                                  <div
                                    className="h-96"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    No Image found
                                  </div>
                                )}
                              </div>
                            </div>
                            <h2
                              key={idx}
                              className="flex items-end justify-center py-2 text-xl font-bold leading-5 text-center border-t font-font-2 border-text-1"
                              style={{ height: 70 }}
                            >
                              {dayjs.unix(hID.date).format('M-D-YYYY')}: GRO{' '}
                              <div
                                className="text-xs ml-2 text-black text-center"
                                style={{ position: 'relative' }}
                              >
                                <div
                                  className="flex bg-gray-100"
                                  style={{
                                    backgroundColor: '#F7A79D',
                                    height: `${
                                      2 * (hID[viewMode]?.hairDensityScore / 80)
                                    }rem`,
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: 0,
                                    zIndex: '1',
                                  }}
                                ></div>

                                <p
                                  className="px-2 mt-auto text-center"
                                  style={{
                                    zIndex: '1000',
                                    position: 'relative',
                                  }}
                                >
                                  {hID[viewMode]?.hairDensityScore}
                                </p>
                              </div>
                            </h2>
                          </div>
                        )
                    )}
                  </>
                )}
                {/* {(viewMode === "front" ||
                viewMode === "crown" ||
                viewMode === "vertex" ||
                viewMode === "left" ||
                viewMode === "right" ||
                viewMode === "back") && (
                <>
                  {hairImagesDetails.map((hID, idx) => (
                    <h2
                      key={idx}
                      className="flex items-end justify-center pt-4 mt-4 text-xl font-bold leading-5 text-center border-t font-font-2 border-text-1">
                      {dayjs(hID.date).format("MM-DD-YY")}: GRO{" "}
                      <div
                        className="flex ml-2 text-xs text-black bg-gray-100"
                        style={{
                          backgroundColor: "#F7A79D",
                          height: `${
                            2 * (hID[viewMode].hairDensityScore / 80)
                          }rem`,
                        }}>
                        <p className="px-2 mt-auto">
                          {hID[viewMode].hairDensityScore}
                        </p>
                      </div>
                    </h2>
                  ))}
                </>
              )} */}
              </div>
              <button
                className="text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId + 1 < hairImagesDetails.length) {
                    setCurrentId(currentId + 1);
                  }
                }}
              >
                <svg
                  // className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 512"
                  width="50"
                  height="50"
                >
                  <path
                    fill="#6e7884"
                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>

        <PresentationNavbar id={id} tab={2} />
      </main>
      <Loading message={'Loading Hair Gro Tracker'} ready={ready} />
    </>
  );
};

export default HairImages;
